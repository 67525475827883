import React, { useMemo, useCallback, useState, useEffect } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import COMMON from "common";
import ERRORS from "common/errors";
import { CALENDAR_FORMAT } from "common/calendar";
import { getDateOfBirthNRIC } from "common/nric-helpers";
import serveRequestErrors from "common/serve-request-errors";
import formatPassportString from "common/format-passport-string";
import { getDateOfBirthAge } from "common/get-date-of-birth-age";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppSelectInput from "components/app-select-input";
import AppButtonInput from "components/app-button-input";
import AppMobileInput from "components/app-mobile-input";
import AppMaskingInput from "components/app-masking-input";
import AppCalendarInput from "components/app-calendar-input";
import AppLoading from "components/pages/page-enquiry/app-loading";
import AppCardStatus from "components/pages/page-enquiry/app-card-status";
import AppCardContainer from "components/pages/page-quotation/app-card-container";
import AppCardInfo from "components/app-card-info";
import pathnames from "routes/pathnames";
import api from "services/api";
import getStateListing from "services/get-state-listing";
import getCitiesListing from "services/get-cities-listing";
import getPostcodeListing from "services/get-postcode-listing";
import getRelationshipListing from "services/get-relationship-listing";
import getIdentificationListing from "services/get-identification-listing";
import getNationalityListing from "services/get-nationality-listing";
import getCountryListing from "services/get-country-listing";
import { setApplicant, setSummaryDetail, setTempFiles } from "store/slices/travel";
import FileUpload from "components/app-upload-files";
import TemplateFile from "assets/Template/TravelTemplate.xlsx"
import AppCardInfoBenefitCoverage from "components/app-card-info-benefit-coverage"

const calendarDisplayFormat = CALENDAR_FORMAT.DATE_FORMAT + " " + CALENDAR_FORMAT.MONTH_FORMAT + " " + CALENDAR_FORMAT.YEAR_FORMAT;

const PageApplicant = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const travel = useSelector((state) => state.travel);
	const [messageType, setMessageType] = useState("");
	const tripDetails = useMemo(() => travel?.travelDetails, [travel?.travelDetails]);
	const quotationInfo = useMemo(() => travel?.quotation, [travel?.quotation]);
	const quotationInfoList = useMemo(() => travel?.quotationList, [travel?.quotationList]);
	const applicantInfo = useMemo(() => travel?.applicant, [travel?.applicant]);
	const quotationCheckout = useMemo(() => quotationInfo?.quotationCheckout, [quotationInfo?.quotationCheckout]);

	/* prettier-ignore */
	const initialValues = useMemo(() => {
		let payload = {
			// For Individual/Family
			name: "",
			identificationType: "NRIC",
			nric: "",
			passport: "",
			passportIssuedDate: "",
			passportExpiredDate: "",
			nationality: "",
			gender: "",
			dob: "",
			mobile: "",
			prefixNo: "+60",
			mobileNoFull: "",
			email: "",
			country: "",
			state: "",
			city: "",
			postcode: "",
			correspondenceAddressLine1: "",
			correspondenceAddressLine2: "",
			correspondenceAddressLine3: "",
			coveredMembers: Array(tripDetails?.persons > 1 ? tripDetails.persons - 1 : 0)
				.fill(null)
				.map(() => ({
					name: "",
					identificationType: "NRIC",
					nric: "",
					passport: "",
					passportIssuedDate: "",
					passportExpiredDate: "",
					nationality: "",
					gender: "",
					dob: "",
					relationship: "",
					mobile: "",
					prefixNo: "+60",
					mobileNoFull: "",
					email: "",
			})),
			// For Group Travel
			files: [], // Add files field for group travel
		};

		// Pre-fill from existing applicant info
		if (applicantInfo && tripDetails.coverageType !== "GROUP") {
			payload = {
				...payload,
				name: applicantInfo.applicantInformation.name || "",
				identificationType: applicantInfo.applicantInformation.identificationType || "",
				nric: applicantInfo.applicantInformation.nric || "",
				passport: applicantInfo.applicantInformation.passport || "",
				passportIssuedDate: applicantInfo.applicantInformation.passportIssuedDate || "",
				passportExpiredDate: applicantInfo.applicantInformation.passportExpiredDate || "",
				prefixNo: applicantInfo.applicantInformation.prefixNo || "",
				mobile: applicantInfo.applicantInformation.mobile || "",
				mobileNoFull: applicantInfo.applicantInformation.mobileNoFull || "",
				gender: applicantInfo.applicantInformation.gender || "",
				dob: applicantInfo.applicantInformation.dob || "",
				email: applicantInfo.applicantInformation.email || "",
				correspondenceAddressLine1: applicantInfo.applicantInformation.address1 || "",
				correspondenceAddressLine2: applicantInfo.applicantInformation.address2 || "",
				correspondenceAddressLine3: applicantInfo.applicantInformation.address3 || "",
				state: applicantInfo.applicantInformation.state || "",
				city: applicantInfo.applicantInformation.city || "",
				postcode: applicantInfo.applicantInformation.postcode || "",
				nationality: applicantInfo.applicantInformation.nationality || "",
				country: applicantInfo.applicantInformation.country || "",
				files: applicantInfo.files || [], // Pre-fill files if they exist

				// Pre-fill covered members if they exist
				coveredMembers: Array(tripDetails?.persons > 1 ? tripDetails.persons - 1 : 0)
				.fill(null)
				.map((_, index) => {
				  const existingMember = applicantInfo.coveredMembers?.[index];
				  
				  return {
					name: existingMember?.name || "",
					identificationType: existingMember?.identificationType || "NRIC",
					nric: existingMember?.nric || "",
					passport: existingMember?.passport || "",
					passportIssuedDate: existingMember?.passportIssuedDate || "",
					passportExpiredDate: existingMember?.passportExpiredDate || "",
					nationality: existingMember?.nationality || "",
					gender: existingMember?.gender || "",
					dob: existingMember?.birthDate || "", // Note: using birthDate from API
					relationship: existingMember?.relationship || "",
					mobile: existingMember?.mobile || "",
					prefixNo: existingMember?.prefixNo || "+60",
					mobileNoFull: existingMember?.mobileNoFull || "",
					email: existingMember?.email || "",
				  };
				}),
			};
		}
		return payload;
	}, [applicantInfo, tripDetails]);

	const validationSchema = yup.object().shape({  
		...(tripDetails?.coverageType !== "GROUP" ? {  
		  // Individual/Family validation  
		  name: yup.string().required(ERRORS.REQUIRED),  
		  identificationType: yup.string().required(ERRORS.REQUIRED),  
		  nric: yup.string().when('identificationType', {  
			is: (val) => val === COMMON.IDENTIFICATION_TYPE.NRIC,  
			then: () => yup.string().required(ERRORS.REQUIRED),  
			otherwise: () => yup.string().nullable()  
		  }),  
		  passport: yup.string().when('identificationType', {  
			is: (val) => val === COMMON.IDENTIFICATION_TYPE.PASSPORT,  
			then: () => yup.string().required(ERRORS.REQUIRED),  
			otherwise: () => yup.string().nullable()  
		  }),  
		  nationality: yup.string().required(ERRORS.REQUIRED),  
		  gender: yup.string().when('identificationType', {  
			is: (val) => val === COMMON.IDENTIFICATION_TYPE.PASSPORT,  
			then: () => yup.string().required(ERRORS.REQUIRED),  
			otherwise: () => yup.string().nullable()  
		  }),  
		  dob: yup.string().when('identificationType', {  
			is: (val) => val === COMMON.IDENTIFICATION_TYPE.PASSPORT,  
			then: () => yup.string().required(ERRORS.REQUIRED),  
			otherwise: () => yup.string().nullable()  
		  }),  
		  mobile: yup.string().required(ERRORS.REQUIRED).min(9, ERRORS.MOBILE),  
		  email: yup.string().required(ERRORS.REQUIRED).matches(COMMON.REGEX.EMAIL, ERRORS.EMAIL),  
	  
		  // Address fields validation based on nationality  
		  country: yup.string().when('nationality', {  
			is: 'Malaysian',  
			then: () => yup.string().required(ERRORS.REQUIRED),  
			otherwise: () => yup.string().nullable()  
		  }),  
	  
		  // State, city, postcode validation based on country and nationality  
		  state: yup.string().when(['country', 'nationality'], {  
			is: (country, nationality) => country === 'Malaysia' && nationality === 'Malaysian',  
			then: () => yup.string().required(ERRORS.REQUIRED),  
			otherwise: () => yup.string().nullable()  
		  }),  
		  city: yup.string().when(['country', 'nationality'], {  
			is: (country, nationality) => country === 'Malaysia' && nationality === 'Malaysian',  
			then: () => yup.string().required(ERRORS.REQUIRED),  
			otherwise: () => yup.string().nullable()  
		  }),  
		  postcode: yup.string().when(['country', 'nationality'], {  
			is: (country, nationality) => country === 'Malaysia' && nationality === 'Malaysian',  
			then: () => yup.string().required(ERRORS.REQUIRED),  
			otherwise: () => yup.string().nullable()  
		  }),  
	  
		  // Address lines validation based on nationality  
		  correspondenceAddressLine1: yup.string().when('nationality', {  
			is: 'Malaysian',  
			then: () => yup.string().required(ERRORS.REQUIRED),  
			otherwise: () => yup.string().nullable()  
		  }),  
		  correspondenceAddressLine2: yup.string().when('nationality', {  
			is: 'Malaysian',  
			then: () => yup.string().required(ERRORS.REQUIRED),  
			otherwise: () => yup.string().nullable()  
		  }),  
	  
		  // Covered Members validation (only if persons > 1)  
		  ...(tripDetails?.persons > 1 ? {  
			coveredMembers: yup.array().of(  
			  yup.object().shape({  
				name: yup.string().required(ERRORS.REQUIRED),  
				identificationType: yup.string().required(ERRORS.REQUIRED),  
				nric: yup.string().when('identificationType', {  
				  is: (val) => val === COMMON.IDENTIFICATION_TYPE.NRIC,  
				  then: () => yup.string().required(ERRORS.REQUIRED),  
				  otherwise: () => yup.string().nullable()  
				}),  
				passport: yup.string().when('identificationType', {  
				  is: (val) => val === COMMON.IDENTIFICATION_TYPE.PASSPORT,  
				  then: () => yup.string().required(ERRORS.REQUIRED),  
				  otherwise: () => yup.string().nullable()  
				}),  
				nationality: yup.string().required(ERRORS.REQUIRED),  
				gender: yup.string().when('identificationType', {  
				  is: (val) => val === COMMON.IDENTIFICATION_TYPE.PASSPORT,  
				  then: () => yup.string().required(ERRORS.REQUIRED),  
				  otherwise: () => yup.string().nullable()  
				}),  
				dob: yup.string().when('identificationType', {  
				  is: (val) => val === COMMON.IDENTIFICATION_TYPE.PASSPORT,  
				  then: () => yup.string().required(ERRORS.REQUIRED),  
				  otherwise: () => yup.string().nullable()  
				}),  
				relationship: yup.string().required(ERRORS.REQUIRED),  
				mobile: yup.string().required(ERRORS.REQUIRED).min(9, ERRORS.MOBILE),  
				email: yup.string().required(ERRORS.REQUIRED).matches(COMMON.REGEX.EMAIL, ERRORS.EMAIL)  
			  })  
			)  
		  } : {})  
		} : {  
		  // Group validation  
		  files: yup.array()  
			.min(1, "At least one file is required")  
			.test("fileSize", "File size must be less than 20MB", (value) => {  
			  if (!value) return true;  
			  return value.every(file => file.size <= 20 * 1024 * 1024);  
			})  
			.test("fileType", "Only Excel files are allowed", (value) => {  
			  if (!value) return true;  
			  const validTypes = [  
				'application/vnd.ms-excel',  
				'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'  
			  ];  
			  return value.every(file => validTypes.includes(file.type));  
			})  
		})  
	  }, [  
		// Define dependencies for when conditions  
		['nationality', 'country'],  
		['identificationType', 'nationality']  
	]);  

	const getGenderFromNRIC = (nric) => {
		if (!nric || nric.length < 12) return '';
		const lastDigit = parseInt(nric.slice(-1));
		return lastDigit % 2 === 0 ? 'FEMALE' : 'MALE';
	};

	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: (values) => {
			//DEBUG
			//console.log("Formik Errors:", formik.errors); // Debugging  
			//console.log("Formik Values:", values); // Debugging  
			//if (Object.keys(formik.errors).length > 0) {  
			//return; // Prevent submission if there are errors  
			//}  
			onHandleSubmit(values);
		},
		
	});

	const onHandleNavigateQuotation = () => {
		sessionStorage.removeItem('fromSum');
		sessionStorage.setItem('fromApp', 'true');
		navigate(pathnames.quotation);
	};

	const onHandleNavigateSummary = () => {
		sessionStorage.removeItem('fromQuot');
		sessionStorage.setItem('fromApp', 'true');
		navigate(pathnames.summary);
	};


	const onHandleSubmit = async (values) => {  
		let response = null;  

		const formatDateForSubmission = (date) => {
			return date ? new Date(date).toISOString() : null;
		};
	
		try {  
			let payload;  
	
			if (tripDetails?.coverageType === "GROUP") {  
				// Group travel submission  
				const formData = new FormData();  
	
				// Append trip details and plan ID  
				formData.append("TripDetails", JSON.stringify(tripDetails));  
				formData.append("PlanId", JSON.stringify(quotationInfo));  
	
				// Append the Excel file  
				if (values.files && values.files.length > 0) {  
					formData.append("groupMemberFile", values.files[0]);  
				};  
	
				// Log FormData for debugging (optional)  
				console.log("Group Travel FormData:");  
				formData.forEach((value, key) => {  
					console.log(`${key}:`, value);  
				});  
	
				payload = formData;  
	
				// Use different API endpoint for group submission  
				response = await api.post.travel.travelGroup(payload);  
	
				// Extract data from FormData to store in Redux  
				const applicantData = {  
					tripDetails: tripDetails,  
					planId: quotationInfo,  
					groupFile: values.files[0], // Store the uploaded file  
				};  
	
				// Dispatch the extracted data to Redux  
				dispatch(setApplicant(applicantData)); 
				//console.log(applicantData);
				
			} else {  
				// Individual/Family travel submission  
				payload = {  
					TripDetails: tripDetails,
					planId: quotationInfo,
					applicantInformation: {  
						name: values.name,  
						email: values.email,  
						identificationType: values.identificationType,  
						nric: values.nric ?? "",  
						passport: isPassport ? values.passport : "",  
						passportIssuedDate: isPassport ? values.passportIssuedDate : null,  
						passportExpiredDate: isPassport ? values.passportExpiredDate : null,  
						age: getDateOfBirthAge(values.dob),  
						birthDate: formatDateForSubmission(values.dob),  
						gender: values.gender,  
						address1: values.correspondenceAddressLine1,  
						address2: values.correspondenceAddressLine2,  
						address3: values.correspondenceAddressLine3,  
						state: values.state,  
						city: values.city,  
						postcode: values.postcode,  
						prefixNo: values.prefixNo,  
						mobile: values.mobile,  
						mobileNoFull: values.prefixNo + values.mobile,  
						nationality: values.nationality,  
						country: values.country,
					},  
					coveredMembers: values.coveredMembers.map((member) => ({  
						name: member.name,  
						email: member.email,  
						identificationType: member.identificationType,  
						nric: member.nric,  
						passport: member.passport,  
						passportIssuedDate:  formatDateForSubmission(member.passportIssuedDate) ,  
						passportExpiredDate:  formatDateForSubmission(member.passportExpiredDate),  
						age: getDateOfBirthAge(member.dob),  
						birthDate: formatDateForSubmission(member.dob),  
						prefixNo: member.prefixNo,  
						mobile: member.mobile,  
						mobileNoFull: member.prefixNo + member.mobile,  
						gender: member.gender,  
						nationality: member.nationality,  
						relationship: member.relationship,  
					})),  
					tripDetails: tripDetails,  
					planId: quotationInfo,  
					
				};
				
				response = await api.post.travel.travelIn(payload);  

				// Dispatch the applicant information to Redux  
				dispatch(setApplicant(payload));    
			}
			//console.log("Submission payload:", payload);  
			
	
			if (response) {  
				//console.log("Response payload:", response);
				dispatch(setSummaryDetail(response));  
				onHandleNavigateSummary();  
			};  
		} catch (error) {  
			switch (error?.response?.data?.errorCode) {  
				case COMMON.ERRORS.GENERIC:  
				case COMMON.ERRORS.NO_QUOTATION:  
				case COMMON.ERRORS.QUOTATION_NOT_FOUND:  
				case COMMON.ERRORS.QUOTATION_CALL_FAILED:  
				case COMMON.ERRORS.QUOTATION_FAILED_RETRIEVE:  
				case COMMON.ERRORS.COVER_NOTE_FAILED_RETRIEVE:  
					setMessageType(COMMON.MESSAGE_TYPE.APPLICANT_FAILED);  
					break;  
				case COMMON.ERRORS.INVALID_FILE_FORMAT:  
					formik.setFieldError("files", "Invalid file format. Please use the provided template.");  
					break;  
				case COMMON.ERRORS.FILE_PROCESSING_ERROR:  
					formik.setFieldError("files", "Error processing file. Please check the file content.");  
					break;  
				default:  
					serveRequestErrors(error);  
					break;  
			}  
		} finally {  
			formik.setSubmitting(false);  
		}  
	};  

	const isPassport = useMemo(() => formik.values.identificationType === COMMON.IDENTIFICATION_TYPE.PASSPORT, [formik.values.identificationType]);
	const validIdentificationType = useMemo(() => !!formik.values.identificationType, [formik.values.identificationType]);

	// Helper function to check passport type for covered members
	const isPassportCoveredMember = useCallback((index) => 
		formik.values.coveredMembers?.[index]?.identificationType === COMMON.IDENTIFICATION_TYPE.PASSPORT,
		[formik.values.coveredMembers]
	);

	// Helper function to check valid identification for covered members
	const validIdentificationTypeCoveredMember = useCallback((index) => 
		!!formik.values.coveredMembers?.[index]?.identificationType,
		[formik.values.coveredMembers]
	);

	// NRIC change handlers
	const handlePolicyOwnerNRICChange = useCallback((event) => {
		const { value } = event.target;
		formik.setFieldValue('nric', value);
		formik.setFieldValue('passport', '');
		
		if (value.replace(/-/g, '').length === 12) {
			const gender = getGenderFromNRIC(value);
			formik.setFieldValue('gender', gender);
			
			const dob = getDateOfBirthNRIC(value);
			
			if (dob) {
				formik.setFieldValue('dob', dob);
			}

			formik.setFieldValue('nationality', 'Malaysian');
			formik.setFieldValue('country', 'Malaysia');
		}
	}, [formik]);

	const handlePolicyOwnerPassportChange = useCallback((event) => {
		const { value } = event.target;
		formik.setFieldValue('passport', value);
		
		// Clear auto-filled fields when passport is selected
		formik.setFieldValue('gender', '');
		formik.setFieldValue('dob', '');
		formik.setFieldValue('nric', '');
		// Don't clear nationality/country as it might be needed
	}, [formik]);

	const handleCoveredMemberNRICChange = useCallback((memberIndex, event) => {
		const { value } = event.target;
		formik.setFieldValue(`coveredMembers.${memberIndex}.nric`, value);
		formik.setFieldValue(`coveredMembers.${memberIndex}.passport`, '');
		
		if (value.replace(/-/g, '').length === 12) {
			const gender = getGenderFromNRIC(value);
			formik.setFieldValue(`coveredMembers.${memberIndex}.gender`, gender);
			
			const dob = getDateOfBirthNRIC(value);
			if (dob) {
				formik.setFieldValue(`coveredMembers.${memberIndex}.dob`, dob);
			}

			formik.setFieldValue(`coveredMembers.${memberIndex}.nationality`, 'Malaysian');
		}
	}, [formik]);

	const handleCoveredMemberPassportChange = useCallback((memberIndex, event) => {
		const { value } = event.target;
		formik.setFieldValue(`coveredMembers.${memberIndex}.passport`, value);
		formik.setFieldValue(`coveredMembers.${memberIndex}.nric`, '');
		
		// Clear auto-filled fields when passport is selected
		formik.setFieldValue(`coveredMembers.${memberIndex}.gender`, '');
		formik.setFieldValue(`coveredMembers.${memberIndex}.dob`, '');
		formik.setFieldValue(`coveredMembers.${memberIndex}.nric`, '');
		// Don't clear nationality as it might be needed
	}, [formik]);

	// Update the IdentificationField component to handle both cases:
	const IdentificationField = useCallback(({ 
		values, 
		errors, 
		touched, 
		onChange, 
		disabled, 
		isPassport, 
		validIdentificationType,
		isCoveredMember = false,
		memberIndex = null,
		onNRICChange,
		onPassportChange,
	}) => {
		if (!validIdentificationType) return null;
		
		const handleChange = (event) => {
			const { value, name } = event.target;
			
			if (name.includes('nric')) {
				if (isCoveredMember) {
					onNRICChange(memberIndex, event);
				} else {
					onNRICChange(event);
				}
			} else if (name.includes('passport')) {
				if (isCoveredMember) {
					onPassportChange(memberIndex, event);
				} else {
					onPassportChange(event);
				}
			}
		};
		
		const fieldPrefix = isCoveredMember ? `coveredMembers.${memberIndex}.` : '';
		
		if (isPassport) {
			return (
				<>
				<AppInput 
					required 
					type="text" 
					name={`${fieldPrefix}passport`}
					label="Passport" 
					placeholder="e.g. VD1289281" 
					disabled={disabled} 
					value={isCoveredMember ? values.passport : values.passport} 
					error={isCoveredMember ? errors?.passport : errors?.passport} 
					touched={isCoveredMember ? touched?.passport : touched?.passport} 
					onFormat={formatPassportString} 
					onChange={handleChange} 
				/>

					<AppCalendarInput 
						required 
						type="text" 
						name={`${fieldPrefix}passportIssuedDate`}
						label="Passport Issued Date" 
						placeholder="e.g. 16 July 1994" 
						maxDate={new Date()} 
						value={isCoveredMember ? values.passportIssuedDate : values.passportIssuedDate} 
						error={isCoveredMember ? errors?.passportIssuedDate : errors?.passportIssuedDate} 
						touched={isCoveredMember ? touched?.passportIssuedDate : touched?.passportIssuedDate} 
						onChange={onHandleDateChange} 
						displayFormat={calendarDisplayFormat}
					/>

					<AppCalendarInput 
						required 
						type="text" 
						name={`${fieldPrefix}passportExpiredDate`}
						label="Passport Expired Date" 
						placeholder="e.g. 16 July 1994" 
						disabled={disabled} 
						value={isCoveredMember ? values.passportExpiredDate : values.passportExpiredDate} 
						error={isCoveredMember ? errors?.passportExpiredDate : errors?.passportExpiredDate} 
						touched={isCoveredMember ? touched?.passportExpiredDate : touched?.passportExpiredDate} 
						onChange={onHandleDateChange} 
						displayFormat={calendarDisplayFormat}
					/>
					</>
			);
		}
		
		return (
			<AppMaskingInput 
				required 
				type="text" 
				name={`${fieldPrefix}nric`}
				label="NRIC No." 
				placeholder="e.g. 901010-14-1234" 
				disabled={disabled} 
				value={isCoveredMember ? values.nric : values.nric} 
				error={isCoveredMember ? errors?.nric : errors?.nric} 
				touched={isCoveredMember ? touched?.nric : touched?.nric} 
				onChange={handleChange} 
				format={COMMON.MASKING.NRIC} 
			/>
		);
	}, []);


	const getOrdinal = (index) => {
		const ordinals = ["First", "Second", "Third", "Fourth", "Fifth", "Sixth", "Seventh", "Eighth", "Ninth", "Tenth"];
		
		// If the index is less than the length of the ordinals array, return the corresponding ordinal
		return ordinals[index] || `${index + 1}th`;  // For cases beyond "Tenth", fallback to a generic ordinal
	};


	const formatDateWithHyphens = (date) => {
		return date ? new Date(date).toLocaleDateString('en-GB').replace(/\//g, '-') : "-";
	};


	//prettier-ignore
	const TripData = useMemo(() => {
	    const tripTypeMap = {
	        'ONE_WAY': "ONE WAY TRIP",
	        'TWO_WAY': "TWO WAY TRIP",
	        'ANNUAL_TRIP': "ANNUAL TRIP",
	    };

	    const coverageMap = {
	        'INDIVIDUAL': "INDIVIDUAL",
	        'INDIVIDUAL_V_SPOUSE': "INDIVIDUAL WITH SPOUSE",
	        'FAMILY': "FAMILY",
	        'GROUP': "GROUP",
	    };

	    // Base info array with common fields
	    const baseInfo = [
	        { label: "Travel Direction", value: tripDetails?.travelDirection ?? "-" },
	        { label: "Destination", value: tripDetails?.destination ?? "-" },
	        { label: "Coverage Type", value: coverageMap[tripDetails?.coverageType] ?? "-" },
	        { label: "Number of Persons", value: tripDetails?.persons ?? "-" },
	    ];

	    // Date-related fields based on travel direction
	    const dateInfo = tripDetails?.travelDirection === COMMON.TRAVEL_DIRECTION.INBOUND
	        ? [
	            { label: "Arrival Date", value: formatDateWithHyphens(tripDetails?.arrival) ?? "-" },
	            { label: "Return Date", value: formatDateWithHyphens(tripDetails?.return) ?? "-" },
	        ]
	        : [
				{ label: "Trip Type", value: tripTypeMap[tripDetails?.tripType] ?? "-" },
	            { label: "Departure Date", value: formatDateWithHyphens(tripDetails?.depart) ?? "-" },
	            { label: "Return Date", value: formatDateWithHyphens(tripDetails?.return) ?? "-" },
	        ];

	    return {
	        title: "Trip Details",
	        info: [
	            ...baseInfo.slice(0, 1), // Travel Direction
	            ...dateInfo,             // Date fields based on direction
	            ...baseInfo.slice(1)     // Remaining fields
	        ]
	    };
	}, [tripDetails]);

	const QuotationData = useMemo(() => {
		if(!quotationInfoList) return null;
		const quotationDetail = quotationInfoList[0];

	    const baseInfo = [
	        { label: "Provider Name", value: quotationDetail?.provider.providerName ?? "-" },
	        { label: "Plan Name", value: quotationDetail?.plan.planName ?? "-" },
	        { label: "Plan Description", value: quotationDetail?.plan.description ?? "-" },
	    ];

	    return {
	        title: "Quotation Details",
	        info: [
	            ...baseInfo,
	        ]
	    };
	}, []);

	const handleFilesChange = useCallback((files) => {
		formik.setFieldValue('files', files);
  		//dispatch(setTempFiles(files)); // Save files to Redux state
		//console.log("store", manual.tempFiles);
		//console.log("value", formik.values.files);

	  }, [formik, dispatch]);

	const exampleFilesLabel = 
	[
		"IC Front & Back",
		"Passport",
	];

	// Excel file formats
	const excelFileTypes = `
		.xlsx,
		.xls
		`
	.replace(/\s/g, '');

	// Add this helper function for covered member field changes
	const handleCoveredMemberFieldChange = useCallback((memberIndex, fieldName, value) => {
		formik.setFieldValue(`coveredMembers.${memberIndex}.${fieldName}`, value);
	}, [formik]);


	const onHandleDateChange = useCallback((name, value) => {
		// Set time to midnight (00:00:00)
		const normalizeDate = (value) => {
		  if (!value) return null;
		  const newDate = new Date(value);
		  newDate.setHours(0, 0, 0, 0);
		  return newDate;
		};
	  
		const normalizedDate = normalizeDate(value);
		
		// Log the value being set for debugging
		console.log(`Setting ${name} to:`, normalizedDate);
		
		formik.setFieldValue(name, normalizedDate);
	  }, [formik]);


	  const onHandleCoveredMemberDateChange = useCallback((memberIndex, name, value) => {
		const normalizeDate = (value) => {
		  if (!value) return null;
		  const newDate = new Date(value);
		  newDate.setHours(0, 0, 0, 0);
		  return newDate;
		};
	  
		const normalizedDate = normalizeDate(value);
		
		// Log the value being set for debugging
		console.log(`Setting covered member ${memberIndex} ${name} to:`, normalizedDate);
		
		formik.setFieldValue(`coveredMembers.${memberIndex}.${name}`, normalizedDate);
	  }, [formik]);

	
	useEffect(() => {
		// Check if the session flag is set
		const fromQuot = sessionStorage.getItem('fromQuot');
		const fromSum = sessionStorage.getItem('fromSum');
	
		if (!fromQuot && !fromSum) {
		  // If no session flag, redirect to home page
		  navigate(pathnames.home, { replace: true });
		}
	
		// Add event listener to handle page refresh
		const handleBeforeUnload = () => {
		  sessionStorage.removeItem('fromQuot');
		  sessionStorage.removeItem('fromSum');
		};
	
		window.addEventListener('beforeunload', handleBeforeUnload);
	
		// Cleanup the event listener on component unmount
		return () => {
		  window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, [navigate]);

	return (
		<div className="page-applicant">
			<div className="applicant">
				<form className="applicant__form" onSubmit={formik.handleSubmit}>
					<p className="applicant__title">Applicant Details</p>
					<div className="applicant__container">
						<div className="applicant__wrapper">
							{/* prettier-ignore */}
							<AppCardInfo data={TripData} />
							<AppCardInfo data={QuotationData} />
							<AppCardInfoBenefitCoverage quotationInfoList={quotationInfoList} ageGroup="Adult" />

						</div>

						{tripDetails?.coverageType !== "GROUP" && 
							<div className="applicant__wrapper">
								{/* prettier-ignore */}
								<AppCardContainer title="Policy Owner" description="Please complete the policy owner details.">

									<div className="applicant__box">
										<div className="applicant__box-body">
											{/* prettier-ignore */}
											<AppInput required type="text" maxLength={100} name="name" label="Insured Name" placeholder="e.g. Sharen Wong" value={formik.values.name} touched={formik.touched.name} error={formik.errors.name} onChange={formik.handleChange} disabled={formik.isSubmitting} />

											{/* prettier-ignore */}
											<AppSelectInput required type="text" name="identificationType" label="ID Type" placeholder="Please Select" loadOptions={getIdentificationListing} value={formik.values.identificationType} error={formik.errors.identificationType} touched={formik.touched.identificationType} onChange={formik.handleChange} disabled={formik.isSubmitting} />

											{/* prettier-ignore */}
											<IdentificationField values={formik.values} errors={formik.errors} touched={formik.touched} onChange={formik.handleChange} disabled={formik.isSubmitting} isPassport={isPassport} validIdentificationType={validIdentificationType} onNRICChange={handlePolicyOwnerNRICChange} onPassportChange={handlePolicyOwnerPassportChange} />

											{/* prettier-ignore */}
											<AppSelectInput required type="text" name="nationality" label="Nationality" placeholder="Please Select" loadOptions={getNationalityListing} value={formik.values.nationality} error={formik.errors.nationality} touched={formik.touched.nationality} onChange={formik.handleChange} disabled={formik.isSubmitting} />

											{/* prettier-ignore */}
											<AppButtonInput required name="gender" label="Gender" disabled={!isPassport} value={formik.values.gender} error={formik.errors.gender} touched={formik.touched.gender} onChange={formik.setFieldValue} options={COMMON.DROPDOWNS.GENDER} />

											{/* prettier-ignore */}
											<AppCalendarInput required name="dob" label="Date of Birth" placeholder="e.g. 16 July 1994" maxDate={new Date(new Date().setDate(new Date().getDate() - 30))}  displayFormat={calendarDisplayFormat} disabled={!isPassport} value={formik.values.dob} error={formik.errors?.dob} touched={formik.touched?.dob} onChange={onHandleDateChange} />

											{/* prettier-ignore */}
											<AppMobileInput required type="number" maxLength={15} name="mobile" prefixNoName="prefixNo" label="Mobile No" placeholder="e.g. 121231234" value={formik.values.mobile} prefixNo={formik.values.prefixNo} error={formik.errors?.mobile} touched={formik.touched?.mobile} disabled={formik.isSubmitting} onChange={formik?.handleChange} onChangeCode={formik.setFieldValue} />

											{/* prettier-ignore */}
											<AppInput required type="text" maxLength={100} name="email" label="Email Address" placeholder="e.g. username@email.com" value={formik.values.email} touched={formik.touched.email} error={formik.errors.email} onChange={formik.handleChange} disabled={formik.isSubmitting} />

											{formik.values.nationality === "Malaysian" && (<>
												<p class="applicant__box-body-text">Mailing Address</p>

												{/* prettier-ignore */}
												<AppInput required type="text" maxLength={100} name="correspondenceAddressLine1" label="Correspondence Address (Line 1)" placeholder="e.g. 123, Jalan abc/123" value={formik.values.correspondenceAddressLine1} touched={formik.touched.correspondenceAddressLine1} error={formik.errors.correspondenceAddressLine1} onChange={formik.handleChange} disabled={formik.isSubmitting} />

												{/* prettier-ignore */}
												<AppInput required type="text" maxLength={100} name="correspondenceAddressLine2" label="Correspondence Address (Line 2)" placeholder="e.g. 123, Jalan abc/123" value={formik.values.correspondenceAddressLine2} touched={formik.touched.correspondenceAddressLine2} error={formik.errors.correspondenceAddressLine2} onChange={formik.handleChange} disabled={formik.isSubmitting} />

												{/* prettier-ignore */}
												<AppInput type="text" maxLength={100} name="correspondenceAddressLine3" label="Correspondence Address (Line 3)" placeholder="e.g. 123, Jalan abc/123" value={formik.values.correspondenceAddressLine3} touched={formik.touched.correspondenceAddressLine3} error={formik.errors.correspondenceAddressLine3} onChange={formik.handleChange} disabled={formik.isSubmitting} />
												
												{/* prettier-ignore */}  
												<AppSelectInput   
												  required   
												  type="text"   
												  name="country"   
												  label="Country"   
												  placeholder="Please Select"   
												  loadOptions={getCountryListing}   
												  value={formik.values.country}   
												  error={formik.errors.country}   
												  touched={formik.touched.country}   
												  onChange={formik.handleChange}   
												  disabled={formik.isSubmitting}   
												/>  

												{formik.values.country === "Malaysia" && (  
												  <>  
												    {/* State */}  
												    <AppSelectInput   
												      required   
												      type="text"   
												      name="state"   
												      label="State / Province"   
												      placeholder="e.g. Wilayah Persekutuan KL"   
												      disabled={formik.isSubmitting}   
												      loadOptions={getStateListing}   
												      value={formik.values.state}   
												      error={formik.errors.state}   
												      touched={formik.touched.state}   
												      onChange={formik.handleChange}   
												    />  

												    {/* City */}  
												    <AppSelectInput   
												      required   
												      type="text"   
												      name="city"   
												      label="City"   
												      placeholder="Please Select"   
												      disabled={formik.isSubmitting}   
												      loadOptions={getCitiesListing}   
												      value={formik.values.city}   
												      error={formik.errors.city}   
												      touched={formik.touched.city}   
												      onChange={formik.handleChange}   
												    />  

												    {/* Postcode */}  
												    <AppSelectInput   
												      required   
												      type="text"   
												      name="postcode"   
												      label="Postcode"   
												      placeholder="Please Select"   
												      disabled={formik.isSubmitting}   
												      loadOptions={getPostcodeListing}   
												      value={formik.values.postcode}   
												      error={formik.errors.postcode}   
												      touched={formik.touched.postcode}   
												      onChange={formik.handleChange}   
												    />  
												  </>  
												)}  
											</>)}
										</div>
									</div>
								</AppCardContainer>

								{tripDetails?.persons >= 2 && Array.from({ length: tripDetails?.persons - 1 }, (_, index) => (
									<AppCardContainer title={`${getOrdinal(index)} Covered Member Details`}  description="Please complete the covered member details." subDescription="" >
									<div className="applicant__box">
										<div className="applicant__box-body">
											{/* prettier-ignore */}
											<AppInput 
												required 
												type="text" 
												maxLength={100} 
												name={`coveredMembers.${index}.name`}
												label="Insured Name" 
												placeholder="e.g. Sharen Wong" 
												value={formik.values.coveredMembers[index]?.name} 
												touched={formik.touched.coveredMembers?.[index]?.name}
												error={formik.errors.coveredMembers?.[index]?.name}
												onChange={(e) => handleCoveredMemberFieldChange(index, 'name', e.target.value)}
												disabled={formik.isSubmitting} 
											/>

											{/* prettier-ignore */}
											<AppSelectInput 
												required 
												type="text" 
												name={`coveredMembers.${index}.identificationType`} 
												label="ID Type" 
												placeholder="Please Select" 
												loadOptions={getIdentificationListing} 
												value={formik.values.coveredMembers[index]?.identificationType} 
												error={formik.errors.coveredMembers?.[index]?.identificationType} 
												touched={formik.touched.coveredMembers?.[index]?.identificationType}
												onChange={(e) => handleCoveredMemberFieldChange(index, 'identificationType', e.target.value)}
												disabled={formik.isSubmitting} 
											/>

											{/* prettier-ignore */}
											<IdentificationField 
												values={formik.values.coveredMembers[index]} 
												errors={formik.errors.coveredMembers?.[index]} 
												touched={formik.touched.coveredMembers?.[index]} 
												onChange={formik.handleChange}						
												disabled={formik.isSubmitting} 
												isPassport={isPassportCoveredMember(index)} 
												validIdentificationType={validIdentificationTypeCoveredMember(index)}
												isCoveredMember={true}
												memberIndex={index}
												onNRICChange={handleCoveredMemberNRICChange}
												onPassportChange={handleCoveredMemberPassportChange}
											/>

											{/* prettier-ignore */}
											<AppSelectInput 
												required 
												type="text" 
												name={`coveredMembers.${index}.nationality`}
												label="Nationality" 
												placeholder="Please Select" 
												loadOptions={getNationalityListing} 
												value={formik.values.coveredMembers[index]?.nationality} 
												touched={formik.touched.coveredMembers?.[index]?.nationality}
												error={formik.errors.coveredMembers?.[index]?.nationality}
												onChange={(e) => handleCoveredMemberFieldChange(index, 'nationality', e.target.value)}
												disabled={formik.isSubmitting} 
											/>
											
											{/* prettier-ignore */}
											<AppButtonInput 
												required 
												name={`coveredMembers.${index}.gender`}
												label="Gender" 
												disabled={!isPassportCoveredMember(index)} 
												value={formik.values.coveredMembers[index]?.gender} 
												touched={formik.touched.coveredMembers?.[index]?.gender}
												error={formik.errors.coveredMembers?.[index]?.gender}
												onChange={(name, value) => handleCoveredMemberFieldChange(index, 'gender', value)}
												options={COMMON.DROPDOWNS.GENDER} 
											/>
											
											{/* prettier-ignore */}
											<AppCalendarInput 
												required 
												name={`coveredMembers.${index}.dob`}
												label="Date of Birth" 
												placeholder="e.g. 16 July 1994" 
												maxDate={new Date(new Date().setDate(new Date().getDate() - 30))} 
												displayFormat={calendarDisplayFormat} 
												disabled={!isPassportCoveredMember(index)} 
												value={formik.values.coveredMembers[index]?.dob} 
												touched={formik.touched.coveredMembers?.[index]?.dob}
												error={formik.errors.coveredMembers?.[index]?.dob}
												onChange={(name, value) => onHandleCoveredMemberDateChange(index, 'dob', value)}
											/>
											
											{/* prettier-ignore */}
											<AppSelectInput 
												required 
												type="text" 
												name={`coveredMembers.${index}.relationship`}
												label="Relationship with Policy Owner" 
												placeholder="Please Select" 
												loadOptions={getRelationshipListing} 
												value={formik.values.coveredMembers[index]?.relationship} 
												touched={formik.touched.coveredMembers?.[index]?.relationship}
												error={formik.errors.coveredMembers?.[index]?.relationship}
												onChange={(e) => handleCoveredMemberFieldChange(index, 'relationship', e.target.value)}
												disabled={formik.isSubmitting} 
											/>
											
											{/* prettier-ignore */}
											<AppMobileInput 
												required 
												type="number" 
												maxLength={15} 
												name={`coveredMembers.${index}.mobile`}
												prefixNoName={`coveredMembers.${index}.prefixNo`}
												label="Mobile No" 
												placeholder="e.g. 121231234" 
												value={formik.values.coveredMembers[index]?.mobile}
												prefixNo={formik.values.coveredMembers[index]?.prefixNo}
												error={formik.errors.coveredMembers?.[index]?.mobile}
												touched={formik.touched.coveredMembers?.[index]?.mobile}
												disabled={formik.isSubmitting} 
												onChange={(e) => handleCoveredMemberFieldChange(index, 'mobile', e.target.value)}
												onChangeCode={(name, value) => handleCoveredMemberFieldChange(index, 'prefixNo', value)}
											/>
											
											{/* prettier-ignore */}
											<AppInput 
												required 
												type="text" 
												maxLength={100} 
												name={`coveredMembers.${index}.email`}
												label="Email Address" 
												placeholder="e.g. username@email.com" 
												value={formik.values.coveredMembers[index]?.email} 
												touched={formik.touched.coveredMembers?.[index]?.email}
												error={formik.errors.coveredMembers?.[index]?.email}
												onChange={(e) => handleCoveredMemberFieldChange(index, 'email', e.target.value)}
												disabled={formik.isSubmitting} 
											/>

										</div>
									</div>
								</AppCardContainer>
								))}
								<div className="applicant__button-container">
									<AppButton outline type="button" label="Back" disabled={formik.isSubmitting} onClick={onHandleNavigateQuotation} />
									<AppButton type="submit" label="Next" disabled={formik.isSubmitting} />
								</div>
							</div>
						}

						{tripDetails?.coverageType === "GROUP" &&
							<div className="applicant__wrapper">
								
								<FileUpload 
									templateFile={TemplateFile}
									maxFiles={1} 
									sampleFiles={exampleFilesLabel}  
									label="Upload Supporting Documents" 
									touched={formik.touched.files} 
									error={formik.errors.files} 
									onFilesChange={handleFilesChange} 
									files={formik.values.files}
									accept={excelFileTypes}
									maxFileSize={20 * 1024 * 1024} // 20MB 
								/>
							
								<div className="applicant__button-container">
									<AppButton outline type="button" label="Back" disabled={formik.isSubmitting} onClick={onHandleNavigateQuotation} />
									<AppButton type="submit" label="Next" disabled={formik.isSubmitting} />
								</div>
							</div>
						}
					</div>
				</form>
			</div>

		</div>
	);
};

export default PageApplicant;
