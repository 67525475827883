import React, { useMemo, useCallback, useEffect, Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import COMMON from "common";
import api from "services/api";
import pathnames from "routes/pathnames";
import classNames from "common/class-names";
import { setReceipt } from "store/slices/travel";
//import { formatDatePattern } from "common/calendar";
import serveRequestErrors from "common/serve-request-errors";
import { formatCurrency } from "common/format-currency-pattern";
import AppButton from "components/app-button";
import AppCardStatus from "components/pages/page-enquiry/app-card-status";
import step1Icon from "assets/images/pages/page-receipt/step1-icon.webp";
import step2Icon from "assets/images/pages/page-receipt/step2-icon.webp";
import step3Icon from "assets/images/pages/page-receipt/step3-icon.webp";
import step4Icon from "assets/images/pages/page-receipt/step4-icon.webp";
import paymentSuccessIcon from "assets/images/pages/page-receipt/payment-success-icon.webp";
import appleAppStoreIcon from "assets/images/pages/page-app-store/apple-app-store-icon.svg";
import googleAppStoreIcon from "assets/images/pages/page-app-store/google-app-store-icon.svg";
import huaweiAppStoreIcon from "assets/images/pages/page-app-store/huawei-app-store-icon.svg";
import AppLoading from "components/pages/page-enquiry/app-loading";

const PageReceipt = (props) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const cancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);
	const [messageType, setMessageType] = useState("");
	const travel = useSelector((state) => state.travel);
	const receiptDetails = useMemo(() => travel?.receipt, [travel?.receipt]);
	const [isLoading, setIsLoading] = useState(true);

	//Placeholder
	//const quotationInfoList = useMemo(() => travel?.quotationList, [travel?.quotationList]);
	//const tripDetails = useMemo(() => travel?.travelDetails, [travel?.travelDetails]);
	//const applicantInfo = useMemo(() => travel?.applicant, [travel?.applicant]);

	const onHandleNavigateHome = () => {
		navigate(pathnames.home);
	};

	const onHandleNavigateDownload = useCallback(() => {
		navigate(pathnames.appStore);
	}, [navigate]);

	const onHandleNavigateAppIos = () => {
		window.open(process.env.REACT_APP_APP_STORE);
	};

	const onHandleNavigateAppAndroid = () => {
		window.open(process.env.REACT_APP_PLAY_STORE);
	};

	const onHandleNavigateAppHuawei = () => {
		window.open(process.env.REACT_APP_GALLERY);
	};

	const formatDateWithHyphens = (date) => {
		return date ? new Date(date).toLocaleDateString('en-GB').replace(/\//g, '-') : "-";
	};

	const isPassport = (idType) =>{
		return idType === COMMON.IDENTIFICATION_TYPE.PASSPORT || idType === "Passport";
	};


	//const quotationDetail = quotationInfoList[0];

	// const getBenefitList = useMemo(() => {
	// 	const benefitList = quotationInfoList?.flatMap((quotation) => quotation.ageGroup.benefits
	// 		.map((benefit) => ({
	// 			benefitName: benefit.benefitName,
	// 			benefitAmount: benefit.coverageAmount,
	// 			ageGroup: quotation.ageGroup.ageGroupName
	// 		}))
	// 	)
	// 	console.log(benefitList)
	// 	return benefitList;
	// }, [quotationInfoList]);

	// const getBenefitNameList = useMemo(() => {  
	// 	const benefitList = quotationInfoList?.flatMap((quotation) =>   
	// 		quotation.ageGroup.benefits.map((benefit) => benefit.benefitName)  
	// 	  );  
	//  
	// 	const uniqueBenefitList = [...new Set(benefitList)];  
	//  
	// 	return uniqueBenefitList;  
	//   }, [quotationInfoList]);

	//   const getBenefitAmountByAgeGroupAndName = (ageGroup, benefitName) => {
	// 	const benefit = getBenefitList.find(
	// 	  (item) => item.ageGroup === ageGroup && item.benefitName === benefitName
	// 	);
	// 	return benefit ? benefit.benefitAmount : "N/A";
	//   };

	const formatDateTimePattern = (date) => {
		if (!date) return "";
		return new Date(date).toLocaleString('en-GB', {
		  day: 'numeric',
		  month: 'short',
		  year: 'numeric',
		  hour: '2-digit',
		  minute: '2-digit',
		  second: '2-digit',
		  hour12: true
		});
	};

	const totalReceiptData = useMemo(() => {
		// Check if coverage type is GROUP
		const isGroup = receiptDetails?.TripDetails.coverageType === 'GROUP';
		// Check if identification type is passport
		const isPassport = receiptDetails?.PolicyOwner?.IdentificationType?.toUpperCase() === 'PASSPORT';
		const selectedPlan = receiptDetails?.PlanDetail?.find(
			(plan) => plan.plan.id === receiptDetails?.TripDetails.planid
		);	
		return {
			order: [
				{ label: "Reference ID", value: receiptDetails?.Status.ref_id, bold: true },
				{ label: "Reference Code", value: receiptDetails?.Status.referenceCode, bold: true },
				{ label: "Payment Date", value: formatDateTimePattern(receiptDetails?.Status.paymentDate ? new Date(receiptDetails?.Status.paymentDate) : ""), bold: true },
				{ label: "Referral Code", value: receiptDetails?.TripDetails.referralCode, bold: true },
				{ label: "Payment Type", value: receiptDetails?.Status.payment_type, bold: true },
				{ label: "Status", value: receiptDetails?.Status.status, bold: true },
			],
			tripDetails: [
				{ label: "Trip Details", value: "", bold: true },
				{ label: "Travel Direction", value: receiptDetails?.TripDetails.travelDirection },
				{ label: "Trip Type", value: receiptDetails?.TripDetails.tripType },
				{ label: "Arrival Date", value: formatDateWithHyphens(receiptDetails?.TripDetails.arrival) },
				{ label: "Return Date", value: formatDateWithHyphens(receiptDetails?.TripDetails.return) },
				{ label: "Destination", value: receiptDetails?.TripDetails.destination },
				{ label: "Coverage Type", value: receiptDetails?.TripDetails.coverageType },
				{ label: "Number of Persons", value: receiptDetails?.TripDetails.persons },
			],
			planDetails: selectedPlan
            ? [
                  { label: "Plan Details", value: "", bold: true },
                  { label: "Provider Name", value: selectedPlan?.provider?.providerName || "-" },
                  { label: "Plan Name", value: selectedPlan?.plan?.planName ?? "-" },
                  { label: "Plan Description", value: selectedPlan?.plan?.description ?? "-" },
              ]
            : [{ label: "Plan Details", value: "No plan found", bold: true }],
			applicantDetails: isGroup ? [
				// Group coverage - use billing info
				{ label: "Company Details", value: "", bold: true },
				{ label: "Company Name", value: receiptDetails?.BillingInfo.CompanyName },
				{ label: "Registration No", value: receiptDetails?.BillingInfo.RegistrationNo },
				{ label: "TIN", value: receiptDetails?.BillingInfo.Tin },
				{ label: "Email", value: receiptDetails?.BillingInfo.Email },
				{ label: "Contact Number", value: receiptDetails?.BillingInfo.contactNum },
				{ label: "Address", value: [
					receiptDetails?.BillingInfo.Address_1,
					receiptDetails?.BillingInfo.Address_2,
					receiptDetails?.BillingInfo.Address_3
				].filter(Boolean).join(', ') },
			] : [
				// Individual coverage - use policy owner info
				{ label: "Policy Owner", value: "", bold: true },
				{ label: "Name", value: receiptDetails?.PolicyOwner?.FullName },
				{ label: "Email", value: receiptDetails?.PolicyOwner?.Email },
				{ label: "Identification Type", value: receiptDetails?.PolicyOwner?.IdentificationType },
				// Conditional rendering for identification based on type
				...(isPassport ? [
					{ label: "Passport Number", value: receiptDetails?.PolicyOwner?.IdentificationNumber },
					{ label: "Passport Issue Date", value: formatDateWithHyphens(receiptDetails?.PolicyOwner?.PassportIssueDate) },
					{ label: "Passport Expiry Date", value: formatDateWithHyphens(receiptDetails?.PolicyOwner?.PassportExpiryDate) },
				] : [
					{ label: "NRIC", value: receiptDetails?.PolicyOwner?.IdentificationNumber },
				]),
				{ label: "Age", value: receiptDetails?.PolicyOwner?.Age ? `${receiptDetails?.PolicyOwner?.Age} years old` : "" },
				{ label: "Birth Date", value: formatDateWithHyphens(receiptDetails?.PolicyOwner?.DOB) },
				{ label: "Gender", value: receiptDetails?.PolicyOwner?.Gender },
				{ label: "Mobile", value: receiptDetails?.PolicyOwner?.Mobile },
				{ label: "Nationality", value: receiptDetails?.PolicyOwner?.Nationality },
			],
			insurancePremium: [
				{ label: "Insurance Premium", value: "", bold: true },
				{ label: "Gross Premium", value: `RM${formatCurrency(receiptDetails?.Status.amount_cents / 100)}`, bold: true },
				{ label: "SST (8%)", value: `Included` },
				{ label: "Stamp Duty Fee", value: `Included` },
			],
			totalPayable: [
				{ label: "Total Payable:", value: `RM${formatCurrency(receiptDetails?.Status.amount_cents / 100)}`, bold: true }
			]
		};
	}, [receiptDetails]);

	 const className = useCallback((obj) => {
	 	return classNames({
	 		"quotation-list__text": true,
	 		"quotation-list__description": obj?.bold,
	 	});
	 }, []);

	 const valueClassName = useCallback((obj) => {
	 	return classNames({
	 		"quotation-list__text": !obj?.bold,
	 		"quotation-list__text--value": !obj?.bold,
	 		"quotation-list__text--value--alignLeft": obj?.alignLeft,
	 		"quotation-list__description": obj?.bold,
	 		"quotation-list__description--value": obj?.bold,
	 	});
	 }, []);

	 const headerClassName = useCallback((obj) => {
	 	return classNames({
	 		"quotation-list__description": true,
	 		"quotation-list__description--value": obj?.bold,
	 	});
	 }, []);

	 const onHandleCheckPaymentStatus = useCallback(async () => {
	 	let response = null;
		setIsLoading(true);

	 	const queryString = window.location.search;
	 	const urlParams = new URLSearchParams(queryString);
	 	const paymentId = urlParams.get("paymentId");

	 	try {
	 		let payload = {
	 			paymentId: paymentId,
	 		};

	 		response = await api.get.travel.paymentStatus(payload);

	 		if (response.status.status === COMMON.MESSAGE_TYPE.PAID) {
	 			let data = {
					TripDetails: {
						travelDirection: response.tripDetail.tripDirection,
						tripType: response.tripDetail.tripType,
						arrival: response.tripDetail.travelStartDate,
						depart: response.tripDetail.travelStartDate,
						return: response.tripDetail.travelEndDate,
						coverageType: response.tripDetail.coverageType,
						destination: response.tripDetail.destinationCountry,
						adult: response.tripDetail.numberOfAdults,
						child: response.tripDetail.numberOfChildren,
						senior: response.tripDetail.numberOfSenior,
						persons: response.tripDetail.numberOfAdults + response.tripDetail.numberOfChildren + response.tripDetail.numberOfSenior,
						referralCode: response.tripDetail.referralCode,
						planid: response.tripDetail.planid,
					},
					Status: {
						referenceCode: response.status.vendor_payment_id,
						paymentDate: response.status.payment_date,
						amount_cents: response.status.amount_cents,
						status: response.status.status,
						ref_id: response.status.ref_id,
						payment_type: response.status.payment_type,
					},
					PlanDetail: response.planDetail.map((detail) => ({
						id: detail.id,
						plan: detail.plan,
						provider: detail.provider,
						ageGroup: detail.ageGroup,
						coverageAmount: detail.coverageAmount,
						subLimit: detail.subLimit,
					})),
					PolicyOwner: {
						FullName: response.policyOwner?.fullName,
						Gender: response.policyOwner?.gender,
						DOB: response.policyOwner?.dob,
						Age: response.policyOwner?.age,
						Nationality: response.policyOwner?.nationality,
						IdentificationType: response.policyOwner?.identificationType,
						IdentificationNumber: response.policyOwner?.identificationNumber,
						PassportIssueDate: response.policyOwner?.passportIssueDate,
						PassportExpiryDate: response.policyOwner?.passportExpiryDate,
						Mobile: response.policyOwner?.prefixNo + response.policyOwner?.mobile,
						Email: response.policyOwner?.email,
					},
					BillingInfo: {
						CompanyName: response.billingInfor?.companyName,
						RegistrationNo: response.billingInfor?.registrationNo,
						Tin: response.billingInfor?.tin,
						Email: response.billingInfor?.email,
						contactNum: response.billingInfor?.contactNumber,
						Address_1: response.billingInfor?.address_1,
						Address_2: response.billingInfor?.address_2,
						Address_3: response.billingInfor?.address_3,
					},
					IncludedCoveredMember: response.coveredMember
        				.filter(member => member.included)
        				.map(member => ({
        				    FullName: member.fullName,
        				    Gender: member.gender,
        				    DOB: member.dob,
        				    Age: member.age,
        				    Nationality: member.nationality,
        				    Relationship: member.relationship,
        				    IdentificationType: member.identificationType,
        				    IdentificationNumber: member.identificationNumber,
        				    PassportIssueDate: member.passportIssueDate,
        				    PassportExpiryDate: member.passportExpiryDate,
        				    PrefixNo: member.prefixNo,
        				    Mobile: member.mobile,
        				    Email: member.email,
							Included: member.included
        				})),
					ExcludedCoveredMember: response.coveredMember
        				.filter(member => member.included === false)
        				.map(member => ({
        				    FullName: member.fullName,
        				    Gender: member.gender,
        				    DOB: member.dob,
        				    Age: member.age,
        				    Nationality: member.nationality,
        				    Relationship: member.relationship,
        				    IdentificationType: member.identificationType,
        				    IdentificationNumber: member.identificationNumber,
        				    PassportIssueDate: member.passportIssueDate,
        				    PassportExpiryDate: member.passportExpiryDate,
        				    PrefixNo: member.prefixNo,
        				    Mobile: member.mobile,
        				    Email: member.email,
							Included: member.included
        				})),
					TravelDoc: {
						DocumentType: response.travelDoc?.documentType,
						DocumentName: response.travelDoc?.documentName,
						DocumentDesc: response.travelDoc?.documentDescription,
						FilePath: response.travelDoc?.filePath,
						Status: response.travelDoc?.status,
					},

	 			};

	 			dispatch(setReceipt(data));
	 		} else if (response.status.status === COMMON.MESSAGE_TYPE.PENDING)
			{
	 			setMessageType(COMMON.MESSAGE_TYPE.PENDING);
	 		} else
			{
				setMessageType(COMMON.MESSAGE_TYPE.PAYMENT_FAILED);
			}
	 	} catch (error) {
	 		switch (error?.response?.data?.errorCode) {
	 			case COMMON.ERRORS.PAYMENT_QUOTATION_INVALID:
	 			case COMMON.ERRORS.PAYMENT_EXIST:
	 				setMessageType(COMMON.MESSAGE_TYPE.PAYMENT_FAILED);
	 				break;
	 			default:
	 				serveRequestErrors(error);
	 				break;
	 		}
	 	}
		finally
		{
			setIsLoading(false);
		}
	 }, [dispatch]);

	useEffect(() => {
		onHandleCheckPaymentStatus();
	}, [onHandleCheckPaymentStatus]);

	useEffect(() => {
		return () => {
			cancelRequest(COMMON.ENDPOINT_PATH.INSURER.PAYMENT_STATUS);
		};
	}, [cancelRequest]);
	

	// Helper functions to extract unique age groups and benefits
	const getAgeGroups = (planDetail) => {
		console.log(planDetail);
		return planDetail.map(plan => ({
		  name: plan.ageGroup.ageGroupName,
		  minAge: plan.ageGroup.minAge,
		  maxAge: plan.ageGroup.maxAge,
		  id: plan.ageGroup.id
		}));
	  };

	  const getAllUniqueBenefits = (planDetail) => {
		// Collect all benefits from all age groups
		const allBenefits = new Map();

		planDetail.forEach(plan => {
		  plan.ageGroup.benefits.forEach(benefit => {
			if (!allBenefits.has(benefit.benefitCode)) {
			  allBenefits.set(benefit.benefitCode, {
				name: benefit.benefitName,
				code: benefit.benefitCode,
				category: benefit.benefitCategory // Optional: if you want to group by category
			  });
			}
		  });
		});
	
		return Array.from(allBenefits.values());
	  };

	  const getBenefitAmount = (planDetail, ageGroupId, benefitCode) => {
		const plan = planDetail.find(p => p.ageGroup.id === ageGroupId);
		const benefit = plan?.ageGroup?.benefits?.find(b => b.benefitCode === benefitCode);
		return benefit?.coverageAmount || '-';
	  };

	  // Table component
	  const BenefitsTable = ({ planDetail }) => {
		if(!planDetail) {
		  return null;
		}
		
		const ageGroups = getAgeGroups(planDetail);
		const benefits = getAllUniqueBenefits(planDetail);
		
		// Calculate dynamic styles based on number of columns
		//const columnCount = ageGroups.length + 1; // benefits column + age group columns
	  
		return (
			<div className="receipt-form__wrapper--full">
			  <div className="benefits-header">
				<p className="benefits-header--title">Coverage Benefits</p>
			  </div>
			  <div className="benefits-table-wrapper">
				<table className="benefits-table">
				  <thead>
					<tr>
					  <th>Benefits</th>
					  {ageGroups.map(group => (
						<th key={group.id}>{group.name} 
						<br /> 
						{`( ${group.minAge} - ${group.maxAge} )`}</th>
					  ))}
					</tr>
				  </thead>
				  <tbody>
					{benefits.map(benefit => (
					  <tr key={benefit.code}>
						<td>{benefit.name}</td>
						{ageGroups.map(group => (
						  <td key={`${benefit.code}-${group.id}`}>
							RM {formatCurrency(getBenefitAmount(planDetail, group.id, benefit.code))}
						  </td>
						))}
					  </tr>
					))}
				  </tbody>
				</table>
			  </div>
			</div>
		);
	  };

	return (
		<div className="page-receipt">
			<div className="receipt">
				{isLoading ? (
                <div className="receipt__body">
                    <AppLoading isLoading={isLoading} text="Loading Receipt Details - We're currently fetching your receipt." />
                </div>
            	) : messageType ? (
					<div className="receipt__body">
						<AppCardStatus messageType={messageType} />
					</div>
				) : (
					<Fragment>
						<div className="receipt__body">
							<img className="receipt__icon" src={paymentSuccessIcon} alt="payment success" />
							<p className="receipt__status">Payment Successful!</p>

							<div className="receipt__container">
								<div className="receipt__wrapper">
									<p className="receipt__title">Payment Summary</p>
								</div>

								<div className="receipt__wrapper">
									<div className="receipt-form__container">
										{ <div className="receipt-form__header">
											{totalReceiptData.order.map((o) => {
												return (
													<ul className="header-quotation-list" key={o.label}>
														<li className="header-quotation-list__item">
															<p className={className()}>{o.label}</p>
															<p className={headerClassName({ bold: o.bold, value: true })}>{o.value}</p>
														</li>
													</ul>
												);
											})}
										</div>}

										<div className="receipt-form__divider" />

										<div className="receipt-form__body">
											<div className="receipt-form__wrapper">
												{totalReceiptData.tripDetails.map((o) => {
													if (!o.hidden) {
													return (
														<ul className="quotation-list" key={o.label}>
														<li className="quotation-list__item">
															<p className={className({ bold: o.bold })}>{o.label}</p>
															<p className={valueClassName({ bold: o.bold, value: true })}>{o.value}</p>
														</li>
														</ul>
													);
													}
													return null;
												})}
											</div>

											<div className="receipt-form__wrapper">
											    {totalReceiptData.planDetails.map((o) => {
											        if (!o.hidden) {
											            return (
											                <ul className="quotation-list" key={o.label}>
											                    <li className="quotation-list__item">
											                        <p className={className({ bold: o.bold })}>{o.label}</p>
											                        <p className={valueClassName({ bold: o.bold, value: true })}>{o.value}</p>
											                    </li>
											                </ul>
											            );
											        }
											        return null;
											    })}
											</div>

											<BenefitsTable planDetail={receiptDetails?.PlanDetail} />
										</div>

										
												
										<div className="receipt-form__divider" />

										
										<div className="receipt-form__body">
											<div className="receipt-form__wrapper">
												{totalReceiptData.applicantDetails?.map((o) => {
													if (!o.hidden) {
													return (
														<ul className="quotation-list" key={o.label}>
														<li className="quotation-list__item">
															<p className={className({ bold: o.bold })}>{o.label}</p>
															<p className={valueClassName({ bold: o.bold, value: true })}>{o.value}</p>
														</li>
														</ul>
													);
													}
													return null;
												})}
											</div>

											<div className="receipt-form__wrapper">
												{totalReceiptData.insurancePremium.map((o) => {
														return (
															<ul className="quotation-list" key={o.label}>
																<li className="quotation-list__item">
																	<p className={className({ bold: o.bold })}>{o.label}</p>
																	<p className={valueClassName({ bold: o.bold, value: true })}>{o.value}</p>
																</li>
															</ul>
														);
													})}


												<div className="receipt-form__divider">
													<div className="receipt-form__payable-amount">
														{totalReceiptData.totalPayable.map((o) => {
															return (
																<ul className="quotation-list" key={o.label}>
																	<li className="quotation-list__item">
																		<p className={className({ bold: o.bold })}>{o.label}</p>
																		<p className={valueClassName({ bold: o.bold, value: true })}>{o.value}</p>
																	</li>
																</ul>
															);
														})}
													</div>
												</div>
											</div>
										</div>
										
										<div className="receipt-form__divider"/>

										<div className="receipt-form__body">
											{receiptDetails?.IncludedCoveredMember.map((member, index) => {
													return (
														<div className="receipt-form__wrapper">
															<li className="quotation-list__item">
																<p className={className({ bold: true})}>Covered Member {index + 1}</p>
															</li>	
															<ul className="quotation-list" key={member.FullName}>
																<li className="quotation-list__item">
																	<p className={className()}>Name</p>
																	<p className={valueClassName({ bold: false})}>{member.FullName}</p>
																</li>
																<li className="quotation-list__item">
																	<p className={className()}>Email</p>
																	<p className={valueClassName({ bold: false })}>{member.Email}</p>
																</li>
																<li className="quotation-list__item">
																	<p className={className()}>Identification Type</p>
																	<p className={valueClassName({ bold: false })}>{member.IdentificationType}</p>
																</li>
																{!isPassport(member.IdentificationType) ?
																	<li className="quotation-list__item">
																		<p className={className()}>Identification Number</p>
																		<p className={valueClassName({ bold: false })}>{member.IdentificationNumber}</p>
																	</li>
																	:
																	<>
																		<li className="quotation-list__item">
																			<p className={className()}>Passport</p>
																			<p className={valueClassName({ bold: false })}>{member.IdentificationNumber}</p>
																		</li>
																		<li className="quotation-list__item">
																			<p className={className()}>Passport Issued Date</p>
																			<p className={valueClassName({ bold: false })}>{formatDateWithHyphens(member.PassportIssueDate)}</p>
																		</li>
																		<li className="quotation-list__item">
																			<p className={className()}>Passport Expired Date</p>
																			<p className={valueClassName({ bold: false })}>{formatDateWithHyphens(member.PassportExpiryDate)}</p>
																		</li>
																	</>
																}
																<li className="quotation-list__item">
																	<p className={className()}>Age</p>
																	<p className={valueClassName({ bold: false })}>{member.Age + " years old"}</p>
																</li>
																<li className="quotation-list__item">
																	<p className={className()}>Birth Date</p>
																	<p className={valueClassName({ bold: false })}>{formatDateWithHyphens(member.DOB)}</p>
																</li>
																<li className="quotation-list__item">
																	<p className={className()}>Gender</p>
																	<p className={valueClassName({ bold: false })}>{member.Gender}</p>
																</li>
																<li className="quotation-list__item">
																	<p className={className()}>Mobile</p>
																	<p className={valueClassName({ bold: false })}>{member.Mobile}</p>
																</li>
																<li className="quotation-list__item">
																	<p className={className()}>Nationality</p>
																	<p className={valueClassName({ bold: false })}>{member.Nationality}</p>
																</li>
															</ul>
														</div>
														
													);
												})}
											</div>

											{receiptDetails?.IncludedCoveredMember.length > 0 && (
														<div className="receipt-form__divider" />
											)}

											
											<div className="receipt-form__body">
											{receiptDetails?.ExcludedCoveredMember.map((member, index) => {
													return (
														<div className="receipt-form__wrapper">
															<li className="quotation-list__item">
																<p className={className({ bold: true})}>Excluded Member {index + 1}</p>
															</li>	
															<ul className="quotation-list" key={member.FullName}>
																<li className="quotation-list__item">
																	<p className={className()}>Name</p>
																	<p className={valueClassName({ bold: false})}>{member.FullName}</p>
																</li>
																<li className="quotation-list__item">
																	<p className={className()}>Email</p>
																	<p className={valueClassName({ bold: false })}>{member.Email}</p>
																</li>
																<li className="quotation-list__item">
																	<p className={className()}>Identification Type</p>
																	<p className={valueClassName({ bold: false })}>{member.IdentificationType}</p>
																</li>
																{!isPassport(member.IdentificationType) ?
																	<li className="quotation-list__item">
																		<p className={className()}>Identification Number</p>
																		<p className={valueClassName({ bold: false })}>{member.IdentificationNumber}</p>
																	</li>
																	:
																	<>
																		<li className="quotation-list__item">
																			<p className={className()}>Passport</p>
																			<p className={valueClassName({ bold: false })}>{member.IdentificationNumber}</p>
																		</li>
																		<li className="quotation-list__item">
																			<p className={className()}>Passport Issued Date</p>
																			<p className={valueClassName({ bold: false })}>{formatDateWithHyphens(member.PassportIssueDate)}</p>
																		</li>
																		<li className="quotation-list__item">
																			<p className={className()}>Passport Expired Date</p>
																			<p className={valueClassName({ bold: false })}>{formatDateWithHyphens(member.PassportExpiryDate)}</p>
																		</li>
																	</>
																}
																<li className="quotation-list__item">
																	<p className={className()}>Age</p>
																	<p className={valueClassName({ bold: false })}>{member.Age + " years old"}</p>
																</li>
																<li className="quotation-list__item">
																	<p className={className()}>Birth Date</p>
																	<p className={valueClassName({ bold: false })}>{formatDateWithHyphens(member.DOB)}</p>
																</li>
																<li className="quotation-list__item">
																	<p className={className()}>Gender</p>
																	<p className={valueClassName({ bold: false })}>{member.Gender}</p>
																</li>
																<li className="quotation-list__item">
																	<p className={className()}>Mobile</p>
																	<p className={valueClassName({ bold: false })}>{member.Mobile}</p>
																</li>
																<li className="quotation-list__item">
																	<p className={className()}>Nationality</p>
																	<p className={valueClassName({ bold: false })}>{member.Nationality}</p>
																</li>
															</ul>
														</div>
													);
												})}
											</div>

											{receiptDetails?.ExcludedCoveredMember.length > 0 && (
												<div className="receipt-form__divider" />
											)}

										<div className="receipt-form__footer">
											<p className="receipt-form__description">Thank you for insuring with easycover.</p>
										</div>

										<div className="receipt-form__footer">
											<p className="receipt-form__description">The purchase of this insurance product does not guarantee coverage.</p>
											<p className="receipt-form__description">All coverage is subject to and governed by the sole discretion of the underwriting provider's assessment, terms, conditions, and policy wording. </p>
											<p className="receipt-form__description">The final determination of coverage, including but not limited to acceptance, scope, limitations, and exclusions, remains exclusively with the underwriting provider, regardless of premium payment.  </p>
										</div>

										<div className="receipt-form_footer">
											<p className="receipt-form__description">
												
													<a href={receiptDetails?.TravelDoc?.FilePath} target="_blank" rel="noreferrer">Click here</a> to read more about our provider's policy wording.
												
											</p>
										</div>
										
										<div className="receipt-form__footer">
											<p className="receipt-form__description">A payment summary / covernote / policy document will be sent to your email within 24 hours. </p>
										</div>

										
										
									</div>
								</div>
							</div>

							<div className="step__body">
								<div className="step__wrapper">
									<p className="step__text">Download our mobile app to view policy details. To get started, please follow these simple steps:</p>
								</div>

								<div className="step__wrapper">
									<div className="step__box">
										<p className="step__title">Step 1</p>

										<div className="step__content">
											<img src={step1Icon} alt="step1" />
											<p className="step__text">Download ‘easycover.my’ app from App Store, Google Play, App Gallery or click the link to download the app.</p>
										</div>
									</div>

									<div className="step__box">
										<p className="step__title">Step 2</p>

										<div className="step__content">
											<img src={step2Icon} alt="step2" />
											<p className="step__text">Login in or create an account using the same email address that purchase the insurance plan.</p>
										</div>
									</div>

									<div className="step__box">
										<p className="step__title">Step 3</p>

										<div className="step__content">
											<img src={step3Icon} alt="step3" />
											<p className="step__text">Tap on the My Coverage.</p>
										</div>
									</div>

									<div className="step__box">
										<p className="step__title">Step 4</p>

										<div className="step__content">
											<img src={step4Icon} alt="step4" />
											<p className="step__text">Tap to view policy details.</p>
										</div>
									</div>
								</div>

								<div className="step__container">
									<p className="step__sub-title">Step 1: Download the Mobile App</p>

									<div className="step__button-container">
										<button type="button" className="step__button" onClick={onHandleNavigateAppIos}>
											<img src={appleAppStoreIcon} alt="apple" />
										</button>

										<button type="button" className="step__button" onClick={onHandleNavigateAppAndroid}>
											<img src={googleAppStoreIcon} alt="google" />
										</button>

										<button type="button" className="step__button" onClick={onHandleNavigateAppHuawei}>
											<img src={huaweiAppStoreIcon} alt="huawei" />
										</button>
									</div>
								</div>

								<div className="step__container">
									<p className="step__sub-title">Step 2: Create your account</p>
									<p className="step__sub-text">Once the app is installed, open it and tap on “Login/Sign up” and tap on “Sign up” on pop up screen.</p>

									<ul className="step-list">
										<li className="step-list__item">Please sign up with your personal email address and relevant details.</li>
										<li className="step-list__item">Complete the verification process to confirm your account.</li>
									</ul>
								</div>

								<div className="step__container">
									<p className="step__sub-title">Step 3: Explore Your Benefits</p>
									<p className="step__sub-text">
										Congratulations! You are now set up to enjoy the full range of services, including insurance coverage. Inside the app, you can easily view your insurance policy details, coverage limits, claim
										procedures, and more.
									</p>
								</div>
							</div>
						</div>

						<div className="receipt__button-container">
							<AppButton outline type="button" label="Download app" onClick={onHandleNavigateDownload} />
							<AppButton type="button" label="Back to home" onClick={onHandleNavigateHome} />
						</div>
					</Fragment>
				)}
				
			
			</div>
		</div>
	);
};

export default PageReceipt;
